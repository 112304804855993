<template>
	<div>
		<headerbox :logo="logo" :namNum="navindex"></headerbox>
		<div class="back">
			<img src="../../assets/eventtheme/back55.png" alt="">
		</div>
		<div style="background: #F8FDFF;">
			<div class="title-box">
				<div class="title">
					{{title}}
				</div>
			</div>
			
			<div class="box" v-for="(item,index) in date" :key="index">
				<div class="box-reg" v-if="item.imgUrl">
					<img :src="item.imgUrl" alt="" class="logo">
					<div class="wz">
						<div class="title" @click="jump(item.id)">{{item.title}}</div>
						<div class="brief" @click="jump(item.id)" v-html="getText(item.content)"></div>
						<div class="brief-box">
							<div class="time">[{{item.publishTime | format}}]</div>
							<div class="button" @click="jump(item.id)">
								MORE
								<img src="../../assets/eventtheme/more.png" alt="">
							</div>
						</div>
					</div>
				</div>
			
				<div class="dandu" v-else>
					<div class="title" @click="jump(item.id)">{{item.title}}</div>
					<div class="brief" @click="jump(item.id)" v-html="getText(item.content)"></div>
					<div class="brief-box">
						<div class="time">[{{item.publishTime | format}}]
							<div class="button" @click="jump(item.id)">
								MORE
								<img src="../../assets/eventtheme/more.png" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="paging">
				<pagination :total="total" @currentchange="currentchange" :go="12" :pageSize="12"></pagination>
			</div>

		</div>


		<footerbox></footerbox>
	</div>
</template>

<script>
	import headerbox from './component/header.vue'
	import footerbox from './component/footer.vue'
	export default {
		name: '',
		components: {
			headerbox,
			footerbox,
		},
		data() {
			return {
				logo: '',
				columntitle: '',
				title: '',
				navindex: 0,
				keyword:'',
				pageNum:1,
				pageSize:10,
				date:[],
				total:0
			}
		},
		created() {
			this.logo = this.$route.query.logo
			this.navindex = this.$route.query.navindex
			this.title = this.$route.query.title
			this.keyword = this.$route.query.keywords
			this.search()
		},
		filters: {
			format(x) {
				if (x != null && x != '') {
					return x.split(" ")[0]
				}
			}
		},
		methods: {
			currentchange(val) {
				this.pageNum = val
				this.search()
			},
			search() {
				let data = {
					keyword:this.keyword ,
					pageNo: this.pageNum,
					pageSize: this.pageSize,
				}
				this.$api.search(data).then(res => {
					this.total = res.data.data.total
					this.date = res.data.data.records
					
					this.date.forEach(item=>{
						item.title= item.title.replace(/<br>/g, ' ')
					})
				})
			},
			jump(id) {
				let routeData = this.$router.resolve({
					path: '/internationalEn/details',
					query: {
						subjectid: id,
						logo: this.$route.query.logo,
						navindex: this.$route.query.navindex,
						id:this.$route.query.id,
						refresh: new Date().getTime()
					}
				});
				window.open(routeData.href, '_blank');
			},
			getText(str) {
				const regExp = /<[^>]+>/g; // 匹配所有HTML标签
				const plainText = str.replace(regExp, ''); // 去除所有标签
				return plainText
			},
		}
	}
</script>

<style scoped lang="scss">
	.back {
		height: 547px;
		width: 100%;
	
		img {
			width: 100%;
			max-width: 100%;
			height: 100%;
			max-height: 100%;
			object-fit: cover;
		}
	}

	.dh {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		font-size: 21px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #000000;
		padding-top: 33px;
	}

	.title-box {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		padding-top: 50px;

		.title {
			width: 360px;
			height: 65px;
			background: url('../../assets/eventtheme/title3.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			font-size: 52px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #0091FF;
			text-align: center;
		}
	}
	
	.box {
		width: 1200px;
		margin: 98px auto 0;
	
		.box-reg {
			display: flex;
			margin-bottom: 87px;
	
			.logo {
				min-width: 590px;
				max-width: 590px;
				height: 375px;
			}
	
			.wz {
				min-width: 574px;
				margin-left: 36px;
	
				.title {
					font-size: 33px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					line-height: 50px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					cursor: pointer;
				}
				
				.title:hover{
					color: #0091FF;
				}
	
				.brief {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					line-height: 42px;
					margin-top: 39px;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					cursor: pointer;
					text-indent: 40px;
				}
	
				.brief-box {
					display: flex;
					justify-content: space-between;
					margin-top: 92px;
					align-items: center;
	
					.time {
						font-size: 21px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #7D7D7D;
					}
	
					.button {
						width: 250px;
						height: 47px;
						background: #F8FDFF;
						border: 1px solid #000000;
						border-radius: 28px;
						font-size: 19px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
	
						img {
							width: 30px;
							height: 13px;
							margin-left: 15px;
						}
					}
				}
			}
		}
	
		.dandu {
			width: 1200px;
			margin: 0 auto;
			margin-bottom: 87px;
	
			.title {
				font-size: 33px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				line-height: 50px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
			
			.title:hover{
				color: #0091FF;
			}
	
			.brief {
				font-size: 20px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				line-height: 42px;
				margin-top: 39px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				cursor: pointer;
				text-indent: 40px;
			}
	
			.brief-box {
				display: flex;
				justify-content: space-between;
				margin-top: 51px;
				align-items: flex-start;
	
				.time {
					font-size: 21px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #7D7D7D;
					margin-top: 17px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					width: 1200px;
				}
	
				.button {
					width: 250px;
					height: 47px;
					background: #F8FDFF;
					border: 1px solid #000000;
					border-radius: 28px;
					font-size: 19px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
	
					img {
						width: 30px;
						height: 13px;
						margin-left: 15px;
					}
				}
			}
		}
	}
	
	.paging {
		width: 1200px;
		display: flex;
		justify-content: center;
		padding-bottom: 73px;
		margin: 0 auto;
	}
</style>